import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Banner1 from "../../../assets/img/slider/1.jpg";
import Banner2 from "../../../assets/img/slider/2.jpg";
import Banner3 from "../../../assets/img/slider/3.jpg";
import Banner4 from "../../../assets/img/slider/4.jpg";
import Banner5 from "../../../assets/img/slider/5.jpg";
import Banner6 from "../../../assets/img/slider/6.jpg";
import Banner7 from "../../../assets/img/slider/7.jpg";
import Banner8 from "../../../assets/img/slider/8.jpg";
import map from "../../../assets/img/map.png";
import call from "../../../assets/img/call.png";
import mail from "../../../assets/img/mail.png";
import logo from "../../../assets/img/logo-new.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import Oval from "../loader/CircleLoade";
import swal from "sweetalert";
export default function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState([]);

  useEffect(() => {
    const userType = localStorage.getItem("user_type");
    const category = localStorage.getItem("category");
    // if (userType === "SALES") {
    //   if (category === "influencer") {
    //     navigate("/influencer_dashboard");
    //   } else if (category === "retailer") {
    //     navigate("/sales_retailer_Dashboard");
    //   } else {
    //     //navigate("/sintex/category");
    // 		navigate("/category2");
    //   }
    // } else if (userType === "RETAILER") {
    //   navigate("/retailer_Dashboard");
    // } else if (userType === "HO") {
    //   navigate("/influencer_dashboard");
    // }

    if (userType && userType !== "" && userType !== "RETAILER") {
      navigate("/category2");
    } else if (userType && userType !== "" && userType === "RETAILER") {
      navigate("/retailer_Dashboard");
    }

    bannerReq();
  }, []);

  const bannerReq = async () => {
    setLoading(true);

    try {
      await axios
        .get(
          Constants.baseUrl +
            `system/web/banner?sortBy=name&sortAsc=true&limit=100&page=1`,
          {
            headers: {
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setLoading(false);
              setBannerData(res.data.output.results);
            } else if (res.data.code === 12002) {
              setLoading(false);
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      // alert('website is under maintenance')
    }
  };

  return (
    <>
      {loading ? <Oval /> : null}
      <nav className="navbar navbar-default">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="true"
              aria-controls="navbar"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link className="navbar-brand" to="#">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div
            id="navbar"
            className="navbar-collapse collapse"
            aria-expanded="true"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="/login" className="active log">
                  <i className="fa fa-sign-in"></i> Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section>
        <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
          {bannerData &&
            bannerData.map((item) => {
              return (
                <>
                  <div>
                    <img src={item.url} alt={item.name} />
                  </div>
                </>
              );
            })}

          {/* <div>
						<img src={Banner2} alt='' />
					</div>
					<div>
						<img src={Banner3} alt='' />
					</div>
					<div>
						<img src={Banner4} alt='' />
					</div>
					<div>
						<img src={Banner5} alt='' />
					</div>
					<div>
						<img src={Banner6} alt='' />
					</div>
					<div>
						<img src={Banner7} alt='' />
					</div>
					<div>
						<img src={Banner8} alt='' />
					</div> */}
        </Carousel>
      </section>
      <section id="contactinfo">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>LET'S GET IN TOUCH!</h2>
                <div className="separator"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col--xs-12 col-sm-4">
              <figure className="infodetail">
                <img src={map} className="slide-in-blurred-top" alt="" />
                <figcaption>
                  <h4> Sintex Loyalty Program Center</h4>
                  <address>
                    GS Tower 112, Level 4,
                    <br />
                    Udyog Vihar Phase - 1, <br /> Sector 20, Gurugram,
                    <br /> Haryana -122008, India
                    <br />
                  </address>
                </figcaption>
              </figure>
            </div>
            <div className="col-md-4 col--xs-12 col-sm-4">
              <figure className="infodetail">
                <img src={call} className="slide-in-blurred-top" alt="" />
                <figcaption>
                  <h4>18001212764</h4>
                  <address>
                    <i className="fa fa-clock-o"></i>: 9:30 am to 5:30 pm
                    (Mon-Fri){" "}
                  </address>
                </figcaption>
              </figure>
            </div>
            <div className="col-md-4 col--xs-12 col-sm-4">
              <figure className="infodetail">
                <img src={mail} className="slide-in-blurred-top" alt="" />
                <figcaption>
                  <h4 className="mail">info@sintexhamesha.com </h4>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
